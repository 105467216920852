import * as React from "react";

import "../Hero.css";

export interface HeroStatsProps {
  name: string;
  level: number;
  imgUrl: string;
  rosterImgUrl: string;

  hp: number;
  dodge: number;
  spd: number;
  crit: number;
  dmgMin: number;
  dmgMax: number;

  onChangeLevel: (level: number) => void;
}

export default function HeroStats(props: HeroStatsProps) {
  const handleChangeLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputLevel = parseInt(e.target.value);
    if (inputLevel < 1 || inputLevel > 5) return;

    props.onChangeLevel(inputLevel);
  };

  return (
    <div className="hero-carac" data-testid="herocarac">
      <picture>
        <source media="(min-width: 700px)" srcSet={props.imgUrl} />
        <source media="(min-width: 0)" srcSet={props.rosterImgUrl} />
        <img src={props.imgUrl} alt={props.name} draggable="false" />
      </picture>

      <table>
        <tbody>
          <tr>
            <td>level</td>
            <td>
              <input
                type="number"
                className="hero-input"
                value={props.level}
                onChange={handleChangeLevel}
              />
            </td>
          </tr>
          <tr>
            <td>hp</td>
            <td>
              <span>{props.hp}</span>
            </td>
          </tr>
          <tr>
            <td>dodge</td>
            <td>
              <span>{props.dodge}</span>
            </td>
          </tr>
          <tr>
            <td>spd</td>
            <td>
              <span>{props.spd}</span>
            </td>
          </tr>
          <tr>
            <td>crit</td>
            <td>
              <span>{props.crit}</span>
            </td>
          </tr>
          <tr>
            <td>dmg</td>
            <td>
              <span>{`[${props.dmgMin}-${props.dmgMax}]`}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

HeroStats.defaultProps = {
  level: 1,
  imgUrl: "",
  rosterImgUrl: "",

  hp: 0,
  dodge: 0,
  spd: 0,
  crit: 0,
  dmgMin: 0,
  dmgMax: 0,


  onChangeLevel: (level: number) => {},
};
