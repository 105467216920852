import React from 'react';
import ReactDOM from 'react-dom';

import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import { config } from './tools/config';

import * as serviceWorker from './serviceWorker';
import App from './App';

import './i18n'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.AUTH_DOMAIN}
      clientId={config.AUTH_CLIENTID}
      redirectUri={window.location.origin}
      audience={config.AUTH_TOKEN_AUDIENCE}
      scope={config.AUTH_SCOPE}
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
