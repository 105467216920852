import { IHero } from "./contracts";

export async function getHeroes(): Promise<IHero[]> {
    return await fetch("heroes.json").then(resp => resp.json())
}

export const getHeroReferences = async (name: string) => {
    const response: IHero[] = await fetch("heroes.json").then(resp => resp.json())
    return response.filter(h => h.name === name)[0]
}

export const getCombo = async (
    rank4HeroName: string,
    rank3HeroName: string,
    rank2HeroName: string,
    rank1HeroName: string) => {
    const response: any[] = await fetch("combos.json").then(resp => resp.json())
    const filteredCombos = response.filter(party => {
        return party.combo[0] === rank4HeroName
            && party.combo[1] === rank3HeroName
            && party.combo[2] === rank2HeroName
            && party.combo[3] === rank1HeroName
    })
    return filteredCombos.length === 0 ? "" : filteredCombos[0].name
}
