import React from 'react'
import { IHero, IHeroInParty, IHeroStats } from '../../contracts'

interface HeroesPoolProps {
    heroes: IHero[]

    onDrag: (hero: IHeroInParty) => void
    onClick: (hero: IHeroInParty) => void
}

export default function HeroesPool(props: HeroesPoolProps) {

    const defaultHeroToDrag = (hero: IHero): any => {
        const stats = hero.stats && hero.stats[0] ? { ...hero.stats[0] } : {} as IHeroStats
        const spells = hero.spells ? hero.spells.map(spell => {
            return { ...spell, isSelected: hero.name === "abomination" }
        }) : []

        return {
            rank: 0,
            level: 1,
            name: hero.name,
            imgUrl: hero.partyImg ? hero.partyImg : hero.imgUrl,
            rosterImgUrl: hero.rosterImg,
            ...stats,
            spells,
        }
    }

    return (
        <div className="pool-heroes columns">
            {props.heroes.map(hero => {
                return (
                    <div className="hero" key={hero.name} draggable="true"
                        onDragStart={() => props.onDrag(defaultHeroToDrag(hero))}
                        onClick={() => props.onClick(defaultHeroToDrag(hero))}
                    >
                        <img src={hero.rosterImg ? hero.rosterImg : hero.imgUrl} alt={hero.name} draggable="false" />
                    </div>
                )
            })}
        </div>
    )
}