import { Layout } from "@gamate/components/layout";

import { config } from "./tools/config";
import PartyBuilder from "./pages/PartyBuilder";

import "./App.css";

export default function App() {
  return (
    <Layout config={config}>
      <PartyBuilder />
    </Layout>
  );
}
