import * as React from 'react'

import './Hero.css'

interface HeroContainerProps {
    onDragHero: () => void
    onDragOverHero: () => void
    onDropHero: () => void

    onClose: () => void
}

export default function HeroContainer(props: React.PropsWithChildren<HeroContainerProps>) {

    const defaultStyle = { width: "25%" }
    const [style, setStyle] = React.useState(defaultStyle as any)

    const handleClear = (e: any) => {
        props.onClose()
    }

    const handleDrag = (e: any) => {
        props.onDragHero()
    }

    const handleDrop = (e: any) => {
        e.preventDefault()
        setStyle(defaultStyle)
        props.onDropHero()
    }

    const handleDragOver = (e: any) => {
        e.preventDefault()
        setStyle({ ...defaultStyle, border: "solid 1px" })
        props.onDragOverHero()
    }

    const handleDragExit = (e: any) => {
        e.preventDefault()
        setStyle(defaultStyle)
    }

    return (
        <div
            draggable={props.children ? true : false}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragExit}
            onDragStart={handleDrag}
            style={style}
        >
            {!props.children &&
                <div className="empty-rank"></div>
            }
            {props.children &&
                <div className="hero-in-party">
                    <div style={{ textAlign: "right", height: "15px", cursor: "pointer" }} onClick={handleClear}>x</div>
                    <div>{props.children}</div>
                </div>
            }
        </div>
    )
}

HeroContainer.defaultProps = {
    onDragHero: () => { },
    onDragOverHero: () => { },
    onDropHero: () => { },
    onInternallyDropHero: () => { },
    onClose: () => { }
}
