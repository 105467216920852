import React from 'react';

import { IHeroInParty, ISpell } from '../../contracts';

import './Party.css'
import HeroContainer from './HeroContainer';
import HeroStats from './HeroCarac';
import HeroSpells from './HeroSpells';

interface PartyProps {
  rank4Hero: IHeroInParty
  rank3Hero: IHeroInParty
  rank2Hero: IHeroInParty
  rank1Hero: IHeroInParty

  onDrop: (rank: number) => void
  onDrag: (rank: number) => void
  onDragOver: (rank: number) => void
  onClose: (rank: number) => void

  onChangeLevel: (level: number, rank: number) => void
  onClickSpell: (spellIndex: number, rank: number) => void
}

const emptyHero = { spells: [] as ISpell[] } as IHeroInParty

export default function Party(props: PartyProps) {

  const createContainer = (
    rank: number,
    hero: IHeroInParty) => {
    return (
      <HeroContainer
        onDropHero={() => props.onDrop(rank)}
        onDragHero={() => props.onDrag(rank)}
        onDragOverHero={() => props.onDragOver(rank)}
        onClose={() => props.onClose(rank)}
      >
        {hero.name &&
          <>
            <HeroStats {...hero}
              onChangeLevel={(level: number) => props.onChangeLevel(level, rank)}
            />
            <HeroSpells key={`${hero.name}${rank}`}
              spells={hero.spells}
              heroDmgMax={hero.dmgMax}
              heroDmgMin={hero.dmgMin}
              rank={rank}
              onClick={(spellIndex: number) => props.onClickSpell(spellIndex, rank)}
            />
          </>
        }
      </HeroContainer>
    )
  }

  return (
    <div className="columns">
      {createContainer(4, props.rank4Hero)}
      {createContainer(3, props.rank3Hero)}
      {createContainer(2, props.rank2Hero)}
      {createContainer(1, props.rank1Hero)}
    </div>
  );
}

Party.defaultProps = {
  rank4Hero: emptyHero,
  rank3Hero: emptyHero,
  rank2Hero: emptyHero,
  rank1Hero: emptyHero,

  onDrop: () => { },
  onDrag: () => { },
  onDragOver: () => { },
  onClose: () => { },

  onChangeLevel: () => { },
  onClickSpell: () => { }
}