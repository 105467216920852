import React from 'react'
import { ISpell } from '../../../contracts'

import Spell from '../Spell'

import * as helpers from '../../../helpers'

interface IHeroSpellsProps {
    rank: number

    spells: ISpell[]

    heroDmgMin: number
    heroDmgMax: number

    onClick: (spellIndex: number) => void
}

const keyGenerator = (spellName: string, rank: number) => {
    return `${spellName}${rank}`
}

export default function HeroSpells(props: IHeroSpellsProps) {

    const handleClick = (spellIndex: number) => {
        const selectedSpells = props.spells.filter(spell => spell.isSelected)
        if (selectedSpells.length === 4 && !props.spells[spellIndex].isSelected) return

        props.onClick(spellIndex)
    }

    return (
        <div data-testid="herospells">
            {props.spells.map((spell, index) => {

                const properties: any = { ...spell }
                if (spell.dmg !== undefined) {
                    properties.dmgMin = (props.heroDmgMin * (1 + spell.dmg)).toFixed(0)
                    properties.dmgMax = (props.heroDmgMax * (1 + spell.dmg)).toFixed(0)
                }

                return (
                    <Spell key={keyGenerator(spell.name, props.rank)}
                        {...properties}
                        isDisabled={helpers.isDisabledSpell(spell.positions, props.rank)}
                        onClick={() => handleClick(index)}
                    />
                )
            })}
        </div>
    )
}

HeroSpells.defaultProps = {
    rank: 0,
    spells: [],
    heroDmgMin: 0,
    heroDmgMax: 0,
    onClick: (spellIndex: number) => { }
}