import React, { useState, useEffect } from "react";

import { bonusOn, debuff, effects } from "../../constants";
import { getCombo, getHeroes } from "../../services";
import { Filters } from "@gamate/components";
import { IHeroInParty, ISpell, IHero, IRecapValue } from "../../contracts";

import * as spellhelpers from "../../helpers";

import HeroesPool from "./HeroesPool";
import Party from "./Party";
import PartyRecap from "./PartyRecap";

import "./PartyBuilder.css";

interface IDraggingState {
  heroDragged: IHeroInParty;
  replacementHero?: IHeroInParty;
  setOrigin?: (hero: IHeroInParty) => void;
}

interface IPartyRecap {
  apply: Map<string, IRecapValue>;
  bonusOn: Map<string, IRecapValue>;
  debuff: Map<string, IRecapValue>;
  party: Map<string, IRecapValue>;
}

const emptyHero = { spells: [] as ISpell[] } as IHeroInParty;

export default function PartyBuilder() {
  const [draggingState, setDraggingState] = useState({} as IDraggingState);

  const [rank4Hero, setRank4Hero] = useState(emptyHero);
  const [rank3Hero, setRank3Hero] = useState(emptyHero);
  const [rank2Hero, setRank2Hero] = useState(emptyHero);
  const [rank1Hero, setRank1Hero] = useState(emptyHero);

  const [heroReferences, setHeroReferences] = useState(new Map() as Map<string, IHero>);

  const [heroesPool, setHeroesPool] = useState<IHero[]>([]);
  const [filteredHeroesPool, setFilteredHeroesPool] = useState<IHero[]>([]);
  const [filter, setFilter] = useState([] as string[]);

  const [partyCombo, setPartyCombo] = React.useState("");
  const [partyRecap, setPartyRecap] = React.useState({} as IPartyRecap);

  // party combo
  useEffect(() => {
    if (!rank4Hero.name || !rank3Hero.name || !rank2Hero.name || !rank1Hero.name) {
      setPartyCombo("");
      return;
    }

    (async () => {
      setPartyCombo(await getCombo(rank4Hero.name, rank3Hero.name, rank2Hero.name, rank1Hero.name));
    })();
  }, [rank4Hero, rank3Hero, rank2Hero, rank1Hero]);

  // party recap
  useEffect(() => {
    const setDico = (
      countByValue: Map<string, IRecapValue>,
      spells: ISpell[],
      rank: number,
      filteringEffect?: (effect: string[]) => string[]
    ) => {
      for (let spell of spells) {
        const effects = filteringEffect ? filteringEffect(spell.effects) : spell.effects;
        for (let elt of effects) {
          if (!countByValue.has(elt)) {
            countByValue.set(elt, { effective: 0, max: 0 });
          }
          const value = countByValue.get(elt) as IRecapValue;
          value.effective += spellhelpers.isDisabledSpell(spell.positions, rank) ? 0 : 1;
          value.max++;
        }
      }
    };

    const setDicos = (partyRecap: IPartyRecap, spells: ISpell[], rank: number) => {
      const selectedSpells = spells.filter((s) => s.isSelected);
      const selectedWithEffectSpells = selectedSpells.filter(
        (s) =>
          s.effects && !s.targets.some((t) => ["self", "party", "r4", "r3", "r2", "r1"].includes(t))
      );

      setDico(partyRecap.apply, selectedWithEffectSpells, rank, spellhelpers.getAppliedEffects);

      const dmgSpell = selectedSpells.filter((s) => s.dmg !== undefined);
      const effectiveDmgSpell = dmgSpell.filter(
        (s) => !spellhelpers.isDisabledSpell(s.positions, rank)
      );
      if (dmgSpell.length > 0)
        partyRecap.apply.set("dmg", { effective: effectiveDmgSpell.length, max: dmgSpell.length });

      setDico(partyRecap.bonusOn, selectedWithEffectSpells, rank, spellhelpers.getBonusOnEffects);
      setDico(partyRecap.debuff, selectedWithEffectSpells, rank, spellhelpers.getDebuffEffects);

      const selectedWithPartyEffectSpells = selectedSpells.filter(
        (s) => s.effects && s.targets.some((t) => ["party", "r4", "r3", "r2", "r1"].includes(t))
      );
      setDico(partyRecap.party, selectedWithPartyEffectSpells, rank);
    };

    const partyRecap: IPartyRecap = {
      apply: new Map<string, any>(),
      bonusOn: new Map<string, any>(),
      debuff: new Map<string, any>(),
      party: new Map<string, any>(),
    };
    setDicos(partyRecap, rank4Hero.spells, 4);
    setDicos(partyRecap, rank3Hero.spells, 3);
    setDicos(partyRecap, rank2Hero.spells, 2);
    setDicos(partyRecap, rank1Hero.spells, 1);

    setPartyRecap(partyRecap);
  }, [rank4Hero, rank3Hero, rank2Hero, rank1Hero]);

  useEffect(() => {
    (async () => {
      const heroes = await getHeroes();
      setHeroesPool(heroes);
      setFilteredHeroesPool(heroes);

      const references = new Map(
        heroes.map((h) => {
          return [h.name, h];
        })
      );
      setHeroReferences(references);
    })();
  }, []);

  useEffect(() => {
    if (filter.length === 0) {
      setFilteredHeroesPool(heroesPool);
      return;
    }

    const includesAtLeastOne = (arr: any[], offset: any[]) => {
      return arr.some((elt) => offset.includes(elt));
    };
    const filteredHeroes = heroesPool.filter(
      (h) =>
        h.spells &&
        h.spells.filter((s) => s.effects && includesAtLeastOne(s.effects, filter)).length > 0
    );

    setFilteredHeroesPool(filteredHeroes);
  }, [filter, heroesPool]);

  const getHeroAtRank = (rank: number): IHeroInParty => {
    switch (rank) {
      case 4:
        return { ...rank4Hero };
      case 3:
        return { ...rank3Hero };
      case 2:
        return { ...rank2Hero };
      case 1:
        return { ...rank1Hero };
    }
    return emptyHero;
  };

  const setHeroAtRank = (hero: IHeroInParty, rank: number) => {
    switch (rank) {
      case 4:
        setRank4Hero(hero);
        break;
      case 3:
        setRank3Hero(hero);
        break;
      case 2:
        setRank2Hero(hero);
        break;
      case 1:
        setRank1Hero(hero);
        break;
    }
  };

  const getAvailableRank = () => {
    if (!rank4Hero.name) return 4;
    if (!rank3Hero.name) return 3;
    if (!rank2Hero.name) return 2;
    if (!rank1Hero.name) return 1;
    return 0;
  };

  const handleDragFromPool = (hero: IHeroInParty) => {
    setDraggingState({ heroDragged: hero });
  };

  const handleClickFromPool = (hero: IHeroInParty) => {
    const availableRank = getAvailableRank();
    if (availableRank === 0) return;
    setHeroAtRank(hero, availableRank);
  };

  const handleDropHero = (rank: number) => {
    draggingState.heroDragged.rank = rank;
    setHeroAtRank(draggingState.heroDragged, rank);

    if (draggingState.setOrigin) {
      draggingState.setOrigin(draggingState.replacementHero as IHeroInParty);
    }
  };

  const handleDragFromParty = (rank: number) => {
    setDraggingState({
      heroDragged: getHeroAtRank(rank),
      setOrigin: (hero: IHeroInParty) => {
        setHeroAtRank(hero, rank);
      },
    });
  };

  const handleDragOver = (rank: number) => {
    const newDragState = {
      replacementHero: getHeroAtRank(rank),
    };
    setDraggingState({ ...draggingState, ...newDragState });
  };

  const handleCloseHero = (rank: number) => {
    setHeroAtRank(emptyHero, rank);
  };

  const handleChangeLevel = (level: number, rank: number) => {
    let newHero = getHeroAtRank(rank);
    newHero.level = level;

    let heroReference: IHero = heroReferences.get(newHero.name) as IHero;
    if (heroReference.stats) {
      newHero = { ...newHero, ...heroReference.stats[level - 1] };
    }

    setHeroAtRank(newHero, rank);
  };

  const handleClickSpell = (spellIndex: number, rank: number) => {
    let newHero = getHeroAtRank(rank);
    if (newHero.name === "abomination") return;

    newHero.spells[spellIndex].isSelected = !newHero.spells[spellIndex].isSelected;
    setHeroAtRank(newHero, rank);
  };

  const partyHeros = { rank4Hero, rank3Hero, rank2Hero, rank1Hero };

  const getDmg = (hero: IHeroInParty, spells: ISpell[]) => {
    let dmgMin = 0;
    let dmgMax = 0;
    const spellsWithDmg = spells
      .filter((s) => s.isSelected && s.dmg !== undefined)
      .map((s) => s.dmg)
      .sort((a: any, b: any) => (a > b ? 1 : -1));
    if (spellsWithDmg.length > 0) {
      const minModifier = spellsWithDmg[0] as number;
      const maxModifier = spellsWithDmg[spellsWithDmg.length - 1] as number;
      dmgMin = hero.dmgMin * (1 + minModifier);
      dmgMax = hero.dmgMax * (1 + maxModifier);
    }
    return { dmgMin, dmgMax };
  };
  const rank4Dmg = getDmg(rank4Hero, rank4Hero.spells);
  const rank3Dmg = getDmg(rank3Hero, rank3Hero.spells);
  const rank2Dmg = getDmg(rank2Hero, rank2Hero.spells);
  const rank1Dmg = getDmg(rank1Hero, rank1Hero.spells);
  const dmgMin = parseInt(
    (rank4Dmg.dmgMin + rank3Dmg.dmgMin + rank2Dmg.dmgMin + rank1Dmg.dmgMin).toFixed(0)
  );
  const dmgMax = parseInt(
    (rank4Dmg.dmgMax + rank3Dmg.dmgMax + rank2Dmg.dmgMax + rank1Dmg.dmgMax).toFixed(0)
  );

  const spellCount = [
    ...rank4Hero.spells,
    ...rank3Hero.spells,
    ...rank2Hero.spells,
    ...rank1Hero.spells,
  ].filter((s) => s.isSelected).length;

  return (
    <div style={{ width: "100%" }}>
      <div className="pool">
        <Filters
          filters={
            new Map([
              ["Can apply", effects],
              ["Debuff", debuff],
              ["Has bonus on", bonusOn],
              ["Heal", ["h_hp", "h_stress"]],
            ])
          }
          selectedFilters={
            new Map([
              ["Can apply", effects.filter((elt) => filter.indexOf(elt) > -1)],
              ["Debuff", debuff.filter((elt) => filter.indexOf(elt) > -1)],
              ["Has bonus on", bonusOn.filter((elt) => filter.indexOf(elt) > -1)],
              ["Heal", ["h_hp", "h_stress"].filter((elt) => filter.indexOf(elt) > -1)],
            ])
          }
          filterRender={(key) => {
            return <>{key}</>;
          }}
          onFilterClick={(selectedElements) => {
            setFilter([...selectedElements.values()].flat());
          }}
        />

        <HeroesPool
          heroes={filteredHeroesPool}
          onDrag={handleDragFromPool}
          onClick={handleClickFromPool}
        />
      </div>
      <div className="party">
        <div className="combo">{partyCombo}</div>
        <PartyRecap
          apply={partyRecap.apply}
          bonusOn={partyRecap.bonusOn}
          debuff={partyRecap.debuff}
          party={partyRecap.party}
          spellCount={spellCount}
          dmgMin={dmgMin}
          dmgMax={dmgMax}
        />
        <Party
          {...partyHeros}
          onDrop={handleDropHero}
          onDrag={handleDragFromParty}
          onDragOver={handleDragOver}
          onClose={handleCloseHero}
          onChangeLevel={handleChangeLevel}
          onClickSpell={handleClickSpell}
        />
      </div>
    </div>
  );
}
