import styled, { css } from "styled-components";

import * as helpers from "../../../helpers";

import "./Spell.css";

interface ISpellProps {
  name: string;
  imgUrl: string;

  effects: string[];
  selfEffects: string[];

  isDisabled: boolean;
  isSelected: boolean;

  dmgMin?: number;
  dmgMax?: number;

  onClick: () => void;
}

const Container = styled.div<any>`
  ${(props) =>
    props.isSelected &&
    css<any>`
      opacity: ${(props) => (props.isDisabled ? "0.3" : "1")};
      border: solid 1px;
      background-color: var(--label-color);
    `}

  ${(props) =>
    !props.isSelected &&
    css<any>`
      opacity: ${(props) => (props.isDisabled ? "0.3" : "0.9")};
      margin-bottom: 2px;
    `}
`;

export default function Spell(props: ISpellProps) {
  const spellCarac: string[] = [];

  // dmg
  if (props.dmgMin && props.dmgMax) spellCarac.push(`[${props.dmgMin}-${props.dmgMax}]`);

  // effects
  if (props.effects) {
    const apply = helpers.getAppliedEffects(props.effects);
    if (apply.length > 0) spellCarac.push(`apply ${apply.join(", ")}`);

    const bonusOn = helpers.getBonusOnEffects(props.effects);
    if (bonusOn.length > 0) spellCarac.push(`bonus on ${bonusOn.join(", ")}`);

    const debuff = helpers.getDebuffEffects(props.effects);
    if (debuff.length > 0) spellCarac.push(`debuff ${debuff.join(", ")}`);

    const positiveEffects = [
      ...helpers.getHealEffects(props.effects),
      ...helpers.getBuffEffects(props.effects),
    ];
    if (positiveEffects.length > 0) spellCarac.push(`positive ${positiveEffects.join(", ")}`);
  }

  // self effects
  if (props.selfEffects && props.selfEffects.length > 0)
    spellCarac.push(`self ${props.selfEffects.join(", ")}`);

  return (
    <Container
      className="spell columns"
      onClick={props.onClick}
      isDisabled={props.isDisabled}
      isSelected={props.isSelected}
    >
      <div>
        <img src={props.imgUrl} alt="" draggable={false} title={props.name} />
      </div>
      <div style={{ marginLeft: "5px" }}>
        <div>{props.name}</div>
        <div>
          <ul>
            {spellCarac.map((s) => (
              <li key={s}>{s}</li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
}

Spell.defaultProps = {
  imgUrl: "",

  effects: [],
  selfEffects: [],

  isDisabled: false,
  isSelected: false,

  onClick: () => {},
};
