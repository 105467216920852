import React from 'react';

import './Party.css'
import { IRecapValue } from '../../contracts';

interface PartyRecapProps {
    apply: Map<string, IRecapValue>
    bonusOn: Map<string, IRecapValue>
    debuff: Map<string, IRecapValue>
    party: Map<string, IRecapValue>
    spellCount: number
    dmgMin: number
    dmgMax: number
}

export default function PartyRecap(props: PartyRecapProps) {

    const createRecapTable = (dico: Map<string, IRecapValue>, title: string) => {
        if (!dico) return null
        dico = new Map([...dico].sort((a, b) => a[1].effective < b[1].effective ? 1 : -1))
        const lines = []
        for (let [key, value] of dico) {
            const displayedEffValue = (100 * value.effective / props.spellCount).toFixed(0)
            const displayedMaxValue = (100 * value.max / props.spellCount).toFixed(0)
            const displayedValue = displayedEffValue === displayedMaxValue ? displayedEffValue : `${displayedEffValue}(${displayedMaxValue})`

            const blightStyle = { backgroundColor: "#737719" }
            const bleedStyle = { backgroundColor: "#c21e1e" }
            let style = key.indexOf("blight") !== -1 ? blightStyle : key.indexOf("bleed") !== -1 ? bleedStyle : {}
            lines.push(
                // <tr key={key} style={style}><td>{key}</td><td title="effective(max)%">{`${displayedValue}%`}</td></tr>
                <li key={key} className="recap-element columns" style={style}>
                    <div className="recap-label">{key}</div>
                    <div className="recap-value">{`${displayedValue}%`}</div>
                </li>
            )
        }
        return (
            <>
                <div style={{ textAlign: "center" }}>{title}</div>
                <ul className="recap-list">{lines}</ul>
            </>
        )
    }

    const dmgDisplayed = `[${props.dmgMin}-${props.dmgMax}]`
    return (
        <div>
            <div>efficient in ...</div>
            <div>
                <div>party dmg/round : {dmgDisplayed}</div>
            </div>
            <div>
                <div>Percentages of spells distribution:</div>
                <div className="recap columns">
                    <div className="recap-block">
                        {createRecapTable(props.apply, "apply")}
                    </div>
                    <div className="recap-block">
                        {createRecapTable(props.bonusOn, "bonusOn")}
                    </div>
                    <div className="recap-block">
                        {createRecapTable(props.debuff, "debuff")}
                    </div>
                    <div className="recap-block">
                        {createRecapTable(props.party, "party")}
                    </div>
                </div>
            </div>
        </div>
    )
}