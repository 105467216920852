const prod = {
  API_URL: "https://hots.gamate.gg/api",
  AUTH_TOKEN_AUDIENCE: "https://gamate.eu.auth0.com/api/v2/",
  AUTH_DOMAIN: "gamate.eu.auth0.com",
  AUTH_CLIENTID: "70wu2D12fLGCm3MNoJfg4kVeHRHbCecL",
  AUTH_SCOPE: "read:current_user read:users update:current_user_metadata",

  authTokenAudience: "https://gamate.eu.auth0.com/api/v2/",
};

const dev = {
  API_URL: "http://localhost:4012/api",
  AUTH_TOKEN_AUDIENCE: "https://cherubia.auth0.com/api/v2/",
  AUTH_DOMAIN: "cherubia.auth0.com",
  AUTH_CLIENTID: "JkQBOS6dMCvl1opZkXOIw0qYEtAE4zHb",
  AUTH_SCOPE: "read:current_user read:users update:current_user_metadata",

  authTokenAudience: "https://cherubia.auth0.com/api/v2/",
};

export const config = process.env.NODE_ENV !== "production" ? dev : prod;
