export function isDisabledSpell(positions: number[], rank: number) {
    return positions && !positions.includes(rank)
}


// effects  
// +,- : buff, debuff
// b_ : bonus on
// h_ : heal on
// r+, r- : resistance
export function getAppliedEffects(effects: string[]) {
    return effects.filter(e => 
        e[0] !== "+" 
        && e[0] !== "-" 
        && e.substr(0, 2) !== "b_" 
        && e.substr(0, 2) !== "h_"
        && e.substr(0, 2) !== "r+" 
        && e.substr(0, 2) !== "r-")
}

export function getBonusOnEffects(effects: string[]) {
    return effects.filter(e => e.substr(0, 2) === "b_")
}

export function getDebuffEffects(effects: string[]) {
    return effects.filter(e => e[0] === "-" || e.substr(0, 2) === "r-")
}

export function getBuffEffects(effects: string[]) {
    return effects.filter(e => e[0] === "+" || e.substr(0, 2) === "r+")
}

export function getHealEffects(effects: string[]) {
    return effects.filter(e => e.substr(0, 2) === "h_")
}